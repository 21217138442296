import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';

function AdminPanel() {
  const [logs, setLogs] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (isLoggedIn) {
      fetchLogs();
      const interval = setInterval(fetchLogs, 60000); // Aktualisiere alle 60 Sekunden
      return () => clearInterval(interval);
    }
  }, [isLoggedIn]);

  const fetchLogs = async () => {
    try {
      const response = await fetch('https://bero.fun/admin/logs', {
        method: 'GET',
        credentials: 'include'
      });
      if (response.ok) {
        const data = await response.json();
        setLogs(data);
      } else {
        console.error('Fehler beim Abrufen der Logs:', response.status);
      }
    } catch (error) {
      console.error('Fehler beim Abrufen der Logs:', error);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://bero.fun/admin/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      if (response.ok) {
        setIsLoggedIn(true);
      } else {
        alert('Ungültige Anmeldedaten');
      }
    } catch (error) {
      console.error('Fehler bei der Anmeldung:', error);
    }
  };

  if (!isLoggedIn) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white p-4">
        <h1 className="text-3xl font-bold mb-6">Admin Login</h1>
        <form onSubmit={handleLogin} className="w-full max-w-sm">
          <input
            type="text"
            placeholder="Benutzername"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="mb-4 w-full p-3 bg-gray-700 text-white rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          <input
            type="password"
            placeholder="Passwort"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mb-4 w-full p-3 bg-gray-700 text-white rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          <button
            type="submit"
            className="w-full py-3 px-6 bg-indigo-600 hover:bg-indigo-700 transition-all duration-300 font-semibold rounded-lg"
          >
            Anmelden
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Admin Dashboard</h1>
        <button
          onClick={() => setIsLoggedIn(false)}
          className="py-2 px-4 bg-red-600 hover:bg-red-700 transition-all duration-300 font-semibold rounded-lg flex items-center"
        >
          <FaTimes className="mr-2" /> Abmelden
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-800">
              <th className="p-3 text-left">Benutzername</th>
              <th className="p-3 text-left">Gesendete Follower</th>
              <th className="p-3 text-left">Zeitstempel</th>
            </tr>
          </thead>
          <tbody>
            {logs.map((log, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-700'}>
                <td className="p-3">{log.username}</td>
                <td className="p-3">{log.followers_sent}</td>
                <td className="p-3">{new Date(log.timestamp).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdminPanel;
