import React from 'react';
import { Link } from 'react-router-dom';

const Explanation = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-indigo-900 text-white font-sans">
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8">Wie migos.bot funktioniert</h1>
        <div className="bg-gray-800 bg-opacity-50 p-8 rounded-lg shadow-xl">
          <h2 className="text-2xl font-semibold mb-4">Unser Prozess</h2>
          
          <ol className="list-decimal list-inside space-y-4">
            <li>Geben Sie Ihren Instagram-Benutzernamen ein</li>
            <li>Unser System überprüft Ihr Profil</li>
            <li>Wir senden Ihnen alle 15 Minuten 15 neue Follower</li>
            <li>Ihr Profil wächst kontinuierlich</li>
          </ol>
          
          <p className="mt-8">
            Unser Bot verwendet fortschrittliche Algorithmen, um echte Follower-Aktivitäten zu simulieren. Dies hilft Ihnen, Ihre Instagram-Präsenz auf sichere und effektive Weise zu steigern.
          </p>
        </div>
        <Link to="/" className="inline-block mt-8 bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded transition duration-300">
          Zurück zur Startseite
        </Link>
      </div>
    </div>
  );
};

export default Explanation;

