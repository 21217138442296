import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

const MobileNav = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="md:hidden">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="text-white p-2 focus:outline-none"
      >
        {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
      </button>

      {/* Overlay mit Blur-Effekt */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-40"
             onClick={() => setIsOpen(false)} />
      )}

      {/* Sliding Menu */}
      <div className={`fixed top-0 right-0 h-full w-64 bg-gray-900 transform transition-transform duration-300 ease-in-out z-50 ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      }`}>
        <div className="flex flex-col p-6">
          <div className="flex justify-end">
            <button
              onClick={() => setIsOpen(false)}
              className="text-gray-400 hover:text-white"
            >
              <FaTimes size={24} />
            </button>
          </div>
          
          <nav className="flex flex-col space-y-4 mt-8">
            <Link 
              to="/erklaerung" 
              className="text-gray-300 hover:text-white transition-colors duration-300 text-lg"
              onClick={() => setIsOpen(false)}
            >
              Erklärung
            </Link>
            <Link 
              to="/social-media-tipps" 
              className="text-gray-300 hover:text-white transition-colors duration-300 text-lg"
              onClick={() => setIsOpen(false)}
            >
              Social Media Tipps
            </Link>
            <Link 
              to="/partnerschaften" 
              className="text-gray-300 hover:text-white transition-colors duration-300 text-lg"
              onClick={() => setIsOpen(false)}
            >
              Partnerschaften
            </Link>
            <Link 
              to="/datenschutz" 
              className="text-gray-300 hover:text-white transition-colors duration-300 text-lg"
              onClick={() => setIsOpen(false)}
            >
              Datenschutz
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default MobileNav;

