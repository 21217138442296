import React from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram, FaCheckCircle, FaTimesCircle, FaArrowRight, FaLock, FaFire } from 'react-icons/fa';
import MobileNav from './MobileNav';

const FreeFollowers = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-indigo-900 text-white font-sans">
      {/* Header von App.js */}
      <header className="p-8 bg-black bg-opacity-30">
        <div className="container mx-auto">
          <div className="flex justify-between items-center">
            <h1 className="text-4xl font-bold">migos.bot</h1>
            
            {/* Desktop Navigation */}
            <nav className="hidden md:flex space-x-6">
              <Link to="/erklaerung" className="text-gray-300 hover:text-white transition-colors duration-300">
                Erklärung
              </Link>
              <Link to="/social-media-tipps" className="text-gray-300 hover:text-white transition-colors duration-300">
                Social Media Tipps
              </Link>
              <Link to="/partnerschaften" className="text-gray-300 hover:text-white transition-colors duration-300">
                Partnerschaften
              </Link>
              <Link to="/datenschutz" className="text-gray-300 hover:text-white transition-colors duration-300">
                Datenschutz
              </Link>
            </nav>
            
            {/* Mobile Navigation */}
            <MobileNav />
          </div>
        </div>
      </header>

      {/* Promotion Bar wie in App.js */}
      <a 
        href="https://www.instagram.com/btc.berat" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="block sticky top-0 z-50 bg-gradient-to-r from-yellow-400 via-red-500 to-pink-500 text-white py-2 px-4 text-center"
      >
        <div className="container mx-auto flex items-center justify-center relative">
          <div className="text-center">
            <FaFire className="inline-block mr-2" />
            <span className="font-bold">SONDERANGEBOT:</span>
            <span className="ml-2">10.000 Follower für nur 15€!</span>
          </div>
        </div>
      </a>

      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8">Kostenlose Instagram Follower bekommen (ohne zurück zu folgen)</h1>
        
        <div className="bg-gray-800 bg-opacity-50 p-8 rounded-lg shadow-xl mb-8">
          <h2 className="text-2xl font-semibold mb-6">Die häufigsten Methoden im Vergleich</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
            <div className="border border-red-500 rounded-lg p-6">
              <h3 className="text-xl font-semibold mb-4 flex items-center">
                <FaTimesCircle className="text-red-500 mr-2" />
                Traditionelle Methoden
              </h3>
              <ul className="space-y-3 text-gray-300">
                <li>• Follow/Unfollow Methode (zeitaufwendig)</li>
                <li>• Engagement Groups (unzuverlässig)</li>
                <li>• Like-for-Like Aktionen (ineffektiv)</li>
                <li>• Manuelle Interaktion (zeitraubend)</li>
              </ul>
              <div className="mt-4 text-red-400">
                ⚠️ Risiko von Instagram-Sperren
              </div>
            </div>
            
            <div className="border border-green-500 rounded-lg p-6">
              <h3 className="text-xl font-semibold mb-4 flex items-center">
                <FaCheckCircle className="text-green-500 mr-2" />
                migos.bot Methode
              </h3>
              <ul className="space-y-3 text-gray-300">
                <li>• Automatisierte Follower-Zustellung</li>
                <li>• Keine Gegenfolge nötig</li>
                <li>• 15 Follower alle 15 Minuten</li>
                <li>• 100% kostenlos</li>
              </ul>
              <div className="mt-4 text-green-400">
                ✓ Sicher und Schnell
              </div>
            </div>
          </div>

          {/* Hier sollte ein Bild eingefügt werden */}
          <div className="mb-8 text-center">
            <img 
              src="/images/follower-comparison.png" 
              alt="Vergleich verschiedener Follower-Methoden" 
              className="mx-auto rounded-lg shadow-xl"
            />
            <p className="text-sm text-gray-400 mt-2">
              Wachstumsvergleich: Traditionelle Methoden vs. migos.bot
            </p>
          </div>

          <div className="bg-indigo-900 bg-opacity-50 p-6 rounded-lg mb-8">
            <h3 className="text-xl font-semibold mb-4">Warum migos.bot die beste Wahl ist:</h3>
            <ul className="space-y-4">
              <li className="flex items-center">
                <FaCheckCircle className="text-green-500 mr-2" />
                Keine zeitaufwendigen Follow/Unfollow-Aktionen
              </li>
              <li className="flex items-center">
                <FaCheckCircle className="text-green-500 mr-2" />
                Kontinuierliches, organisch wirkendes Wachstum
              </li>
              <li className="flex items-center">
                <FaCheckCircle className="text-green-500 mr-2" />
                Kein Risiko einer Account-Sperre
              </li>
            </ul>
          </div>

          <div className="text-center">
            <Link 
              to="/" 
              className="inline-flex items-center bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-4 px-8 rounded-lg transition duration-300"
            >
              Jetzt kostenlos Follower bekommen
              <FaArrowRight className="ml-2" />
            </Link>
          </div>
        </div>
      </div>

      {/* Neue Related Links Sektion vor dem Footer */}
      <section className="max-w-5xl mx-auto my-20">
        <h3 className="text-4xl font-bold text-center mb-12">Weitere hilfreiche Ressourcen</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <Link to="/social-media-tipps" className="bg-gray-800 bg-opacity-50 p-6 rounded-lg hover:bg-opacity-70 transition duration-300">
            <h4 className="text-xl font-semibold mb-3">Social Media Tipps</h4>
            <p className="text-gray-300">Lerne die besten Strategien für organisches Wachstum auf Instagram.</p>
          </Link>
          
          <Link to="/erklaerung" className="bg-gray-800 bg-opacity-50 p-6 rounded-lg hover:bg-opacity-70 transition duration-300">
            <h4 className="text-xl font-semibold mb-3">Wie migos.bot funktioniert</h4>
            <p className="text-gray-300">Erfahre mehr über unseren automatisierten Follower-Service.</p>
          </Link>
          
          <Link to="/partnerschaften" className="bg-gray-800 bg-opacity-50 p-6 rounded-lg hover:bg-opacity-70 transition duration-300">
            <h4 className="text-xl font-semibold mb-3">Partnerschaften</h4>
            <p className="text-gray-300">Entdecke Möglichkeiten für Kooperationen und schnelleres Wachstum.</p>
          </Link>
        </div>
      </section>

      {/* Breadcrumb Navigation hinzufügen */}
      <nav className="container mx-auto px-4 py-4 text-sm" aria-label="Breadcrumb">
        <ol className="list-none p-0 flex text-gray-400">
          <li className="flex items-center">
            <Link to="/" className="hover:text-white">Home</Link>
            <span className="mx-2">/</span>
          </li>
          <li className="text-white">Kostenlose Instagram Follower</li>
        </ol>
      </nav>

      {/* Footer wie in App.js */}
      <footer className="mt-16 py-8 bg-black bg-opacity-50 text-center">
        <p className="text-gray-400">&copy; 2024 migos.bot. Alle Rechte vorbehalten.</p>
      </footer>
    </div>
  );
};

export default FreeFollowers;
