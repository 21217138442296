import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaChartLine, FaUsers, FaHandshake  } from 'react-icons/fa';
import { RiMegaphoneFill } from "react-icons/ri";

const PartnershipBenefit = ({ icon, title, description }) => (
  <div className="bg-gray-800 bg-opacity-50 p-6 rounded-lg shadow-xl">
    <div className="text-4xl mb-4 text-indigo-400">{icon}</div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-300">{description}</p>
  </div>
);

const Partnerships = () => {
  const [email, setEmail] = useState('');
  const [instagramName, setInstagramName] = useState('');
  const [message, setMessage] = useState('');
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus('sending');

    try {
      const response = await fetch('https://bero.fun/partnershaft', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, instagramName, message }),
      });

      if (response.ok) {
        setSubmitStatus('success');
        setEmail('');
        setInstagramName('');
        setMessage('');
      } else {
        setSubmitStatus('error');
      }
    } catch (error) {
      console.error('Fehler beim Senden der Anfrage:', error);
      setSubmitStatus('error');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-indigo-900 text-white font-sans">
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8">Partnerschaften & Promo</h1>
        
        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-6">Warum du Partner werden solltest</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <PartnershipBenefit 
              icon={<FaChartLine />}
              title="Organisches Wachstum"
              description="Boost deine Followerzahl auf natürliche Art und bau dir 'ne echte, engagierte Community auf."
            />
            <PartnershipBenefit 
              icon={<FaUsers />}
              title="Community erweitern"
              description="Vernetze dich mit anderen Influencern und Marken, um deine Reichweite zu pushen."
            />
            <PartnershipBenefit 
              icon={<FaHandshake />}
              title="Exklusive Zusammenarbeit"
              description="Profitiere von maßgeschneiderten Werbekampagnen und speziellen Promo-Aktionen."
            />
            <PartnershipBenefit 
              icon={<RiMegaphoneFill />}
              title="Mehr Sichtbarkeit"
              description="Werde auf unserer Plattform hervorgehoben und erreiche ein breiteres Publikum."
            />
          </div>
        </section>
        
        <div className="bg-gray-800 bg-opacity-50 p-8 rounded-lg shadow-xl">
          <h2 className="text-2xl font-semibold mb-4">Kontaktier uns für eine Partnerschaft</h2>
          <p className="mb-6">Füll das Formular aus, um uns wegen einer Partnerschaft anzuschreiben:</p>
          
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="email" className="block mb-2">Deine E-Mail</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 bg-gray-700 rounded-md text-white"
                required
              />
            </div>
            <div>
              <label htmlFor="instagramName" className="block mb-2">Dein Instagram-Name</label>
              <input
                type="text"
                id="instagramName"
                value={instagramName}
                onChange={(e) => setInstagramName(e.target.value)}
                className="w-full p-2 bg-gray-700 rounded-md text-white"
                required
              />
            </div>
            <div>
              <label htmlFor="message" className="block mb-2">Deine Nachricht</label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full p-2 bg-gray-700 rounded-md text-white h-32"
                required
              ></textarea>
            </div>
            <button 
              type="submit" 
              className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded transition duration-300"
              disabled={submitStatus === 'sending'}
            >
              {submitStatus === 'sending' ? 'Wird gesendet...' : 'Anfrage abschicken'}
            </button>
          </form>
          
          {submitStatus === 'success' && (
            <p className="mt-4 text-green-400">Deine Anfrage wurde erfolgreich gesendet!</p>
          )}
          {submitStatus === 'error' && (
            <p className="mt-4 text-red-400">Ups, da ist was schiefgelaufen. Versuch's später nochmal.</p>
          )}
        </div>
        <Link to="/" className="inline-block mt-8 bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded transition duration-300">
          Zurück zur Startseite
        </Link>
      </div>
    </div>
  );
};

export default Partnerships;
