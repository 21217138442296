import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-indigo-900 text-white font-sans">
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8">Datenschutzerklärung</h1>
        <div className="bg-gray-800 bg-opacity-50 p-8 rounded-lg shadow-xl">
          <h2 className="text-2xl font-semibold mb-4">Datenschutzerklärung für www.migos.bot</h2>
          
          <p className="mb-4">
            Auf migos.bot erheben und speichern wir nur Ihren Instagram-Benutzernamen, um unseren Service bereitzustellen. Diese Daten werden nicht an Dritte weitergegeben.
          </p>
          
          <p className="mb-4">
            Unsere Website nutzt außerdem Google Analytics zur Analyse der Website-Nutzung und Google AdSense, um personalisierte Werbung anzuzeigen. Beide Dienste verwenden Cookies, um Informationen zu sammeln. Weitere Informationen finden Sie in den Datenschutzbestimmungen von Google.
          </p>
          
          <p className="mb-4">
            Bei Fragen zum Datenschutz können Sie uns unter support@migos.bot kontaktieren.
          </p>
          
          <p className="mt-8 text-sm text-gray-400">
            Stand: Oktober 2024
          </p>
        </div>
        <Link to="/" className="inline-block mt-8 bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded transition duration-300">
          Zurück zur Startseite
        </Link>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
