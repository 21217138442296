import React from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram, FaCamera, FaHashtag, FaRegClock, FaKeyboard, FaChartLine, FaVideo, FaPalette } from 'react-icons/fa';

const SocialMediaTips = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-indigo-900 text-white font-sans">
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8">Instagram Tipps & Tricks</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-gray-800 bg-opacity-50 p-6 rounded-lg shadow-xl">
            <FaCamera className="text-4xl mb-4 text-indigo-400" />
            <h2 className="text-2xl font-semibold mb-4">Qualität über Quantität</h2>
            <p>Veröffentliche lieber weniger, aber dafür hochwertige Inhalte. Gute Beleuchtung und professionelle Bearbeitung machen den Unterschied.</p>
          </div>
          <div className="bg-gray-800 bg-opacity-50 p-6 rounded-lg shadow-xl">
            <FaHashtag className="text-4xl mb-4 text-indigo-400" />
            <h2 className="text-2xl font-semibold mb-4">Effektive Hashtag-Nutzung</h2>
            <p>Verwende eine Mischung aus populären und Nischen-Hashtags. Recherchiere relevante Tags in deiner Branche. Maximal 30 Hashtags pro Beitrag.</p>
          </div>
          <div className="bg-gray-800 bg-opacity-50 p-6 rounded-lg shadow-xl">
            <FaRegClock className="text-4xl mb-4 text-indigo-400" />
            <h2 className="text-2xl font-semibold mb-4">Optimales Timing</h2>
            <p>Veröffentliche Beiträge zwischen 17 und 18 Uhr für maximale Reichweite. An Wochenenden kannst du auch später posten. Überprüfe deine Insights für die besten Zeiten.</p>
          </div>
          <div className="bg-gray-800 bg-opacity-50 p-6 rounded-lg shadow-xl">
            <FaInstagram className="text-4xl mb-4 text-indigo-400" />
            <h2 className="text-2xl font-semibold mb-4">Interaktion ist wichtig</h2>
            <p>Reagiere auf Kommentare, like Beiträge anderer und baue echte Verbindungen auf. Je mehr du gibst, desto mehr bekommst du zurück.</p>
          </div>
          <div className="bg-gray-800 bg-opacity-50 p-6 rounded-lg shadow-xl">
            <FaKeyboard className="text-4xl mb-4 text-indigo-400" />
            <h2 className="text-2xl font-semibold mb-4">Ansprechende Bildunterschriften</h2>
            <p>Verwende Schlüsselwörter, die deine Zielgruppe sucht. Erzähle Geschichten, stelle Fragen und motiviere deine Follower zum Kommentieren.</p>
          </div>
          <div className="bg-gray-800 bg-opacity-50 p-6 rounded-lg shadow-xl">
            <FaChartLine className="text-4xl mb-4 text-indigo-400" />
            <h2 className="text-2xl font-semibold mb-4">Analysiere deine Leistung</h2>
            <p>Überprüfe regelmäßig deine Instagram Insights. Verstehe, was deine Follower mögen und passe deine Strategie entsprechend an.</p>
          </div>
          <div className="bg-gray-800 bg-opacity-50 p-6 rounded-lg shadow-xl">
            <FaVideo className="text-4xl mb-4 text-indigo-400" />
            <h2 className="text-2xl font-semibold mb-4">Nutze Reels & Stories</h2>
            <p>Verwende Reels für kurze, unterhaltsame Videos. Poste täglich Stories, um deine Persönlichkeit zu zeigen und nah an deinen Followern zu sein.</p>
          </div>
          <div className="bg-gray-800 bg-opacity-50 p-6 rounded-lg shadow-xl">
            <FaPalette className="text-4xl mb-4 text-indigo-400" />
            <h2 className="text-2xl font-semibold mb-4">Einheitlicher Stil</h2>
            <p>Entwickle einen konsistenten Stil für deine Beiträge. Verwende ähnliche Filter oder Farbpaletten, um deinen Feed attraktiv zu gestalten.</p>
          </div>
        </div>
        <Link to="/" className="inline-block mt-8 bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded transition duration-300">
          Zurück zur Startseite
        </Link>
      </div>
    </div>
  );
};

export default SocialMediaTips;
