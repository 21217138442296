import React, { useState, useEffect } from "react";
import './index.css';
import { FaInstagram, FaCheckCircle, FaLock, FaQuestionCircle, FaChartLine, FaUserFriends, FaFire, FaExclamationTriangle } from "react-icons/fa";
import { useGoogleAnalytics } from './useGoogleAnalytics';
import AdminPanel from './AdminPanel';
import followerGif from './follower-animation.gif';
import PrivacyPolicy from './PrivacyPolicy';
import { Routes, Route, Link } from 'react-router-dom';
import Explanation from './Explanation';
import SocialMediaTips from './SocialMediaTips';
import Partnerships from './Partnerships';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import axios from 'axios';
import MobileNav from './MobileNav';
import FreeFollowers from './FreeFollowers';



function App() {
  useGoogleAnalytics();
  
  const [isClaiming, setIsClaiming] = useState(false);
  const [username, setUsername] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('success');
  const [isLoading, setIsLoading] = useState(false);
  const [showTopBar, setShowTopBar] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [gifPlays, setGifPlays] = useState(0);
  const [fadeOut, setFadeOut] = useState(false);
  const [liquidFill, setLiquidFill] = useState(0);
  const [fillButton, setFillButton] = useState(false);
  const [topGainers, setTopGainers] = useState([]);
  const [dailyStats, setDailyStats] = useState([]);
  const [refreshCountdown, setRefreshCountdown] = useState(3);

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768); // 768px ist ein üblicher Breakpoint für mobile Geräte
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  useEffect(() => {
    const gifDuration = 1000; // Angenommene Dauer des GIFs in Millisekunden
    const timer = setInterval(() => {
      setGifPlays(prev => {
        if (prev >= 2) {
          clearInterval(timer);
          setFadeOut(true);
          return prev;
        }
        return prev + 1;
      });
    }, gifDuration);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (fillButton) {
      const timer = setTimeout(() => setFillButton(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [fillButton]);

  useEffect(() => {
    const fetchDailyStats = async () => {
      try {
        const response = await axios.get('https://bero.fun/daily_stats');
        setDailyStats(response.data);
      } catch (error) {
        console.error('Fehler beim Abrufen der täglichen Statistiken:', error);
      }
    };

    fetchDailyStats();

    const interval = setInterval(fetchDailyStats, 3000); // Aktualisiere alle 3 Sekunden

    return () => clearInterval(interval); // Cleanup-Funktion
  }, []);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setRefreshCountdown((prevCount) => (prevCount > 0 ? prevCount - 1 : 3));
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, []);

  const sortedDailyStats = [...dailyStats].sort((a, b) => b.followers_sent - a.followers_sent).slice(0, 10);

  const getUserRanking = (username) => {
    const index = sortedDailyStats.findIndex(stat => stat.username === username);
    return index !== -1 ? index + 1 : null;
  };

  const handleCloseTopBar = () => {
    setShowTopBar(false);
  };

  const handleClaim = async (e) => {
    e.preventDefault();
    if (!username) {
      setPopupMessage("Bitte gib deinen Benutzernamen ein!");
      setPopupType('error');
      setShowPopup(true);
      return;
    }
  
    setIsClaiming(true);
    setIsLoading(true);
    setFillButton(true);
  
    try {
      const response = await fetch(`https://bero.fun/follow?username=${encodeURIComponent(username)}`);
      const data = await response.json();
  
      setPopupMessage(data.message);
      setPopupType(data.success ? 'success' : 'warning');

      if (data.success) {
        const userRanking = getUserRanking(username);
        if (userRanking !== null && userRanking <= 10) {
          setPopupMessage(`${data.message} Glückwunsch! Du bist auf Platz ${userRanking} der Top 10!`);
        }
      }
    } catch (error) {
      console.error("Unerwarteter Fehler beim API-Aufruf:", error);
      setPopupMessage("Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.");
      setPopupType('error');
    } finally {
      setIsClaiming(false);
      setIsLoading(false);
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 5000);
    }
  };




  const addFollowers = async () => {
    try {
      const response = await fetch(`https://bero.fun/follow?username=${encodeURIComponent(username)}`);
      const data = await response.json();
      setPopupMessage(data.message);
      setPopupType(data.success ? 'success' : 'warning');
    } catch (error) {
      console.error("Unerwarteter Fehler beim API-Aufruf:", error);
      setPopupMessage("Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.");
      setPopupType('error');
    }
    setShowPopup(true);
    setTimeout(() => setShowPopup(false), 5000);
  };

  if (window.location.pathname === '/admin') {
    return <AdminPanel />;
  }

  return (
    <Routes>
      <Route path="/" element={
        <div className="min-h-screen bg-gradient-to-br from-gray-900 to-indigo-900 text-white font-sans">
          {showTopBar && (
            <a 
              href="https://www.instagram.com/btc.berat" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="block sticky top-0 z-50 bg-gradient-to-r from-yellow-400 via-red-500 to-pink-500 text-white py-2 px-4 text-center"
            >
              <div className="container mx-auto flex items-center justify-center relative">
                <div className={`text-center ${isMobile ? 'text-sm' : ''}`}>
                  <FaFire className="inline-block mr-2" />
                  <span className="font-bold">SONDERANGEBOT:</span>
                  <span className="ml-2">10.000 Follower für nur 15€!</span>
                  {!isMobile && (
                    <span className="ml-4 bg-white text-black px-4 py-2 rounded-full font-bold hover:bg-gray-200 transition-colors duration-300 inline-block">
                      Jetzt auf Instagram kontaktieren
                    </span>
                  )}
                </div>
                <button 
                  onClick={(e) => {
                    e.preventDefault();
                    handleCloseTopBar();
                  }} 
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 text-white hover:text-gray-200"
                >
                  &times;
                </button>
              </div>
            </a>
          )}

          <header className="p-8 bg-black bg-opacity-30">
            <div className="container mx-auto">
              <div className="flex justify-between items-center">
                <h1 className="text-4xl font-bold">migos.bot</h1>
                
                {/* Desktop Navigation */}
                <nav className="hidden md:flex space-x-6">
                  <Link to="/erklaerung" className="text-gray-300 hover:text-white transition-colors duration-300">
                    Erklärung
                  </Link>
                  <Link to="/social-media-tipps" className="text-gray-300 hover:text-white transition-colors duration-300">
                    Social Media Tipps
                  </Link>
                  <Link to="/partnerschaften" className="text-gray-300 hover:text-white transition-colors duration-300">
                    Partnerschaften
                  </Link>
                  <Link to="/datenschutz" className="text-gray-300 hover:text-white transition-colors duration-300">
                    Datenschutz
                  </Link>
                </nav>
                
                {/* Mobile Navigation */}
                <MobileNav />
              </div>
            </div>
          </header>

          <main className="container mx-auto px-4 py-12">
            <section className="max-w-4xl mx-auto text-center mb-16">
              <h2 className="text-6xl font-extrabold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">MIGOSSSSS</h2>
              <p className="text-2xl mb-10 text-gray-300">Sichere dir alle 15 Minuten 15 kostenlose Follower!</p>
              
              {/* GIF mit sanftem Fade-Out und Collapse Animation */}
              <div 
                className={`w-full max-w-md mx-auto bg-white bg-opacity-10 rounded-lg shadow-xl overflow-hidden mb-8 transition-all duration-2000 ease-in-out ${
                  fadeOut ? 'opacity-0 max-h-0 mb-0' : 'opacity-100 max-h-96'
                }`}
                style={{
                  transition: 'opacity 2s ease-in-out, max-height 2s ease-in-out, margin 2s ease-in-out',
                }}
              >
                <img src={followerGif} alt="Follower-Wachstum Animation" className="w-full h-auto" />
              </div>
            </section>

            {/* Follower beanspruchen Abschnitt */}
            <section className="max-w-md mx-auto bg-gray-800 bg-opacity-50 rounded-lg shadow-2xl p-8 mb-16">
              <h3 className="text-2xl font-semibold mb-6 text-center">Follower beanspruchen</h3>
              
              <form onSubmit={handleClaim} className="space-y-6">
                <div className="relative">
                  <FaInstagram className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Dein Benutzernamen"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="w-full pl-10 pr-3 py-4 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300"
                  />
                </div>
                
                <div className="p-4 bg-indigo-600 bg-opacity-75 rounded-md">
                  <div className="flex items-center mb-2">
                    <FaExclamationTriangle className="text-2xl mr-3 text-yellow-300" />
                    <p className="text-white font-semibold">Wichtiger Hinweis:</p>
                  </div>
                  <p className="text-white text-sm">
                    Folge <a href="https://www.instagram.com/btc.berat" target="_blank" rel="noopener noreferrer" className="underline font-bold hover:text-yellow-200 transition-colors duration-300">@btc.berat</a> und <a href="https://www.instagram.com/teo.usdt" target="_blank" rel="noopener noreferrer" className="underline font-bold hover:text-yellow-200 transition-colors duration-300">@teo.usdt</a> auf Instagram, damit der Bot funktioniert!
                  </p>
                </div>
                
                <button
                  type="submit"
                  disabled={isClaiming || isLoading}
                  className={`w-full py-4 px-6 bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 transition-all duration-300 font-semibold rounded-md flex items-center justify-center ${
                    (isClaiming || isLoading) ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  {isLoading ? <div className="loader mr-2"></div> : <FaLock className="mr-2" />}
                  {isClaiming ? "Follower werden hinzugefügt..." : "Follower sicher beanspruchen"}
                </button>
              </form>
              
              {showPopup && (
                <div className={`mt-4 p-3 rounded-md ${
                  popupType === 'success' ? 'bg-green-600' :
                  popupType === 'warning' ? 'bg-yellow-600' :
                  popupType === 'info' ? 'bg-blue-600' :
                  'bg-red-600'
                }`}>
                  <p className="text-sm">{popupMessage}</p>
                </div>
              )}
            </section>


            {/* Wie es funktioniert Abschnitt */}
            <section className="max-w-5xl mx-auto my-20">
              <h3 className="text-4xl font-bold text-center mb-12">Wie es funktioniert</h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
                <div className="bg-gray-800 bg-opacity-50 p-8 rounded-lg text-center transform hover:scale-105 transition duration-300">
                  <div className="text-5xl font-bold mb-6 text-indigo-400">1</div>
                  <h4 className="text-xl font-semibold mb-4">Gib deinen Nutzernamen ein</h4>
                  <p className="text-gray-300">Einfach deinen Benutzernamen ohne @ eingeben.</p>
                </div>
                <div className="bg-gray-800 bg-opacity-50 p-8 rounded-lg text-center transform hover:scale-105 transition duration-300">
                  <div className="text-5xl font-bold mb-6 text-indigo-400">2</div>
                  <h4 className="text-xl font-semibold mb-4">Wir verifizieren deinen Account</h4>
                  <p className="text-gray-300">Wir überprüfen, ob du beiden Profilen folgst.</p>
                </div>
                <div className="bg-gray-800 bg-opacity-50 p-8 rounded-lg text-center transform hover:scale-105 transition duration-300">
                  <div className="text-5xl font-bold mb-6 text-indigo-400">3</div>
                  <h4 className="text-xl font-semibold mb-4">Erhalte deine Follower</h4>
                  <p className="text-gray-300">Alle 15 Minuten werden dir 15 neue Follower hinzugefügt.</p>
                </div>
              </div>
            </section>

            <section ref={ref} className="max-w-5xl mx-auto my-20">
              <h3 className="text-4xl font-bold text-center mb-12">Unsere Statistiken</h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
                <div className="bg-gray-800 bg-opacity-50 p-8 rounded-lg text-center transform hover:scale-105 transition duration-300">
                  <FaUserFriends className="text-6xl mx-auto mb-6 text-indigo-400" />
                  <div className="text-5xl font-bold mb-4">
                    {inView && <CountUp end={300} duration={2.5} />}+
                  </div>
                  <p className="text-gray-300">Zufriedene Nutzer</p>
                </div>
                <div className="bg-gray-800 bg-opacity-50 p-8 rounded-lg text-center transform hover:scale-105 transition duration-300">
                  <FaChartLine className="text-6xl mx-auto mb-6 text-green-400" />
                  <div className="text-5xl font-bold mb-4">
                    {inView && <CountUp end={20} duration={2} />}K+
                  </div>
                  <p className="text-gray-300">Verteilte Follower</p>
                </div>
                <div className="bg-gray-800 bg-opacity-50 p-8 rounded-lg text-center transform hover:scale-105 transition duration-300">
                  <FaCheckCircle className="text-6xl mx-auto mb-6 text-blue-400" />
                  <div className="text-5xl font-bold mb-4">
                    {inView && <CountUp end={99} duration={3} />}%
                  </div>
                  <p className="text-gray-300">Erfolgsrate</p>
                </div>
              </div>
            </section>

            <section className="max-w-5xl mx-auto my-20">
              <h3 className="text-4xl font-bold text-center mb-12">Top 10 Follower-Gewinner</h3>
              <p className="text-center text-xl mb-8">
                Die Statistiken werden täglich zurückgesetzt. 
                <span className="ml-2 text-indigo-400">Aktualisierung in {refreshCountdown} {refreshCountdown === 1 ? 'Sekunde' : 'Sekunden'}</span>
              </p>
              <div className="bg-gray-800 bg-opacity-50 rounded-lg shadow-xl overflow-hidden">
                <table className="w-full">
                  <thead>
                    <tr className="bg-indigo-600 text-white">
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Rang</th>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Benutzername</th>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Follower erhalten</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-700">
                    {sortedDailyStats.map((stat, index) => (
                      <tr key={index} className={
                        index === 0 
                          ? 'bg-yellow-500 bg-opacity-20 text-yellow-300 font-bold' 
                          : index % 2 === 0 
                            ? 'bg-gray-900 bg-opacity-50' 
                            : 'bg-gray-800 bg-opacity-50'
                      }>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {index === 0 ? '🏆' : index + 1}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">{stat.username}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">{stat.followers_sent}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </section>

            <section className="max-w-5xl mx-auto my-20">
              <h3 className="text-4xl font-bold text-center mb-12">Häufig gestellte Fragen</h3>
              <div className="space-y-6">
                <div className="bg-gray-800 bg-opacity-50 p-6 rounded-lg hover:bg-opacity-70 transition duration-300">
                  <h4 className="text-xl font-semibold mb-3">Ist <strong>migos.bot</strong> wirklich kostenlos?</h4>
                  <p className="text-gray-300">Ja! Für die Follower auf der Seite ist es kostenlos. Wir zahlen die Follower für euch.</p>
                </div>
                <div className="bg-gray-800 bg-opacity-50 p-6 rounded-lg hover:bg-opacity-70 transition duration-300">
                  <h4 className="text-xl font-semibold mb-3">Wie oft kann ich Follower beanspruchen?</h4>
                  <p className="text-gray-300">Du kannst alle 15 Minuten 15 neue Follower beanspruchen. Es gibt kein tägliches Limit.</p>
                </div>
                <div className="bg-gray-800 bg-opacity-50 p-6 rounded-lg hover:bg-opacity-70 transition duration-300">
                  <h4 className="text-xl font-semibold mb-3">Sind die Follower echt?</h4>
                  <p className="text-gray-300">Die Accounts sind nicht echt, haben jedoch Profilbilder und Posts, die denen der echten Accounts sehr ähneln.</p>
                </div>
                <div className="bg-gray-800 bg-opacity-50 p-6 rounded-lg hover:bg-opacity-70 transition duration-300">
                  <h4 className="text-xl font-semibold mb-3">Wie kann ich euch unterstützen?</h4>
                  <p className="text-gray-300">Gar nicht, keiner kann uns das Wasser reichen.</p>
                </div>
              </div>
            </section>
          </main>

          <footer className="mt-16 py-8 bg-black bg-opacity-50 text-center">
            <p className="text-gray-400">&copy; 2024 migos.bot. Alle Rechte vorbehalten.</p>
          </footer>
        </div>
      } />
      <Route path="/datenschutz" element={<PrivacyPolicy />} />
      <Route path="/erklaerung" element={<Explanation />} />
      <Route path="/social-media-tipps" element={<SocialMediaTips />} />
      <Route path="/partnerschaften" element={<Partnerships />} />
      <Route path="/kostenlose-follower" element={<FreeFollowers />} />
    </Routes>
  );
}

export default App;
